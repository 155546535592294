import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { login, register } from '../../api';

const initialState = {
    token: null,
    user: null,
    status: 'idle', // idle | loading | succeeded | failed
    error: null,
    message: null,
};

// Thunks for login and register
export const loginUser = createAsyncThunk('auth/loginUser', async (credentials, { rejectWithValue }) => {
    try {
        const response = await login(credentials); // Call login API
        return response.data; // Return the response data (e.g., token and user info)
    } catch (error) {
        return rejectWithValue(error.response?.data?.message || 'Login failed');
    }
});

export const registerUser = createAsyncThunk('auth/registerUser', async (userInfo, { rejectWithValue }) => {
    try {
        const response = await register(userInfo); // Call register API
        return response.data; // Return the response data (e.g., message and token)
    } catch (error) {
        return rejectWithValue(error.response?.data?.message || 'Registration failed');
    }
});

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        logout: (state) => {
            state.token = null;
            state.user = null;
            state.status = 'idle';
            state.error = null;
            state.message = null;
        },
        resetAuthState: (state) => {
            state.status = 'idle';
            state.error = null;
            state.message = null;
        },
    },
    extraReducers: (builder) => {
        builder
            // Login user cases
            .addCase(loginUser.pending, (state) => {
                state.status = 'loading';
                state.error = null;
                state.message = null; // Clear previous messages
            })
            .addCase(loginUser.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.user = action.payload.user;
                state.token = action.payload.token;
                state.message = 'Login successful'; // Set success message for login
                state.error = null;
            })
            .addCase(loginUser.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload || 'Login failed. Please try again.';
                state.token = null;
                state.message = null; // Clear any success message
            })

            // Register user cases
            .addCase(registerUser.pending, (state) => {
                state.status = 'loading';
                state.error = null;
                state.message = null; // Clear previous messages
            })
            .addCase(registerUser.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.token = action.payload.token;
                state.message = 'Registration successful'; // Set success message for registration
                state.error = null;
            })
            .addCase(registerUser.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload || 'Registration failed. Please try again.';
                state.token = null;
                state.message = null; // Clear any success message
            });
    },
});

// Export the resetAuthState action and logout action
export const { logout, resetAuthState } = authSlice.actions;

export default authSlice.reducer;
