import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loginUser, registerUser } from '../../features/auth/authSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faLock, faEye, faEyeSlash, faPhone, faAddressCard, faUserGear } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate } from 'react-router-dom';
import { faFacebookF, faInstagram, faTwitter, faGoogle } from '@fortawesome/free-brands-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import Toastify CSS
import './AuthForm.css';

const AuthForm = ({ isRegister }) => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [c_password, setC_password] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [phone, setPhone] = useState('');
    const [address, setAddress] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const dispatch = useDispatch();
    const auth = useSelector((state) => state.auth);
    const navigate = useNavigate();

    useEffect(() => {
        // Show success toast after successful registration
        if (auth.message && isRegister) {
            toast.success('Registration successful! Redirecting to login...', {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setTimeout(() => navigate('/login'), 3000); // Redirect after 3 seconds
        }
    
        // Show success toast after successful login
        if (auth.message && !isRegister) {
            toast.success('Login successful! Redirecting...', {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setTimeout(() => navigate('/dashboard'), 3000); // Redirect after 3 seconds
        }
    
        // Show error toast for failed login or registration
        if (auth.error) {
            toast.error(auth.error, {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }, [auth, isRegister, navigate]);
    

    const handleSubmit = (e) => {
        e.preventDefault();
        
        if (isRegister) {
            if (password !== c_password) {
                setErrorMessage('Passwords do not match');
                toast.error('Passwords do not match', {
                    position: 'top-right',
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                return;
            }
            dispatch(registerUser({ name, phone, email, password, c_password, address }));
        } else {
            dispatch(loginUser({ email, password }));
        }
    };

    return (
        <div className="auth-wrapper">
            <ToastContainer /> {/* Toast container for showing notifications */}
            <div className="auth-container">
                <div className="logo-wrapper" style={{ textAlign: 'center' }}>
                    <img src="/assets/img/logo7.png" style={{ marginTop: '1px', marginBottom: '25px' }} alt="App Logo" className="app-logo" />
                </div>
                <h2>{isRegister ? 'Create an Account' : 'Sign In'}</h2>
                <p className="subtitle">
                    {isRegister ? 'Register to get started' : 'We’ll send a confirmation code to your email.'}
                </p>
                <form onSubmit={handleSubmit} className="auth-form">
                    {isRegister && (
                        <>
                            <div className="input-block">
                                <label>Name <span className="required">*</span></label>
                                <div className="input-icon">
                                    <FontAwesomeIcon icon={faUserGear} className="input-fa-icon" />
                                    <input
                                        type="text"
                                        placeholder="Enter your Name"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                </div>
                            </div>

                            <div className="input-block">
                                <label>Phone Number <span className="required">*</span></label>
                                <div className="input-icon">
                                    <FontAwesomeIcon icon={faPhone} className="input-fa-icon" />
                                    <input
                                        type="text"
                                        placeholder="Enter your phone number"
                                        value={phone}
                                        onChange={(e) => setPhone(e.target.value)}
                                    />
                                </div>
                            </div>
                        </>
                    )}

                    <div className="input-block">
                        <label>Email <span className="required">*</span></label>
                        <div className="input-icon">
                            <FontAwesomeIcon icon={faEnvelope} className="input-fa-icon" />
                            <input
                                type="email"
                                placeholder="Enter your email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className="input-block">
                        <label>Password <span className="required">*</span></label>
                        <div className="input-icon">
                            <FontAwesomeIcon icon={faLock} className="input-fa-icon" />
                            <input
                                type={showPassword ? 'text' : 'password'}
                                placeholder="Enter your password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <FontAwesomeIcon
                                icon={showPassword ? faEyeSlash : faEye}
                                className="toggle-password"
                                onClick={() => setShowPassword(!showPassword)}
                            />
                        </div>
                    </div>

                    {isRegister && (
                        <>
                            <div className="input-block">
                                <label>Confirm Password <span className="required">*</span></label>
                                <div className="input-icon">
                                    <FontAwesomeIcon icon={faLock} className="input-fa-icon" />
                                    <input
                                        type={showPassword ? 'text' : 'password'}
                                        placeholder="Confirm your password"
                                        value={c_password}
                                        onChange={(e) => setC_password(e.target.value)}
                                    />
                                    <FontAwesomeIcon
                                        icon={showPassword ? faEyeSlash : faEye}
                                        className="toggle-password"
                                        onClick={() => setShowPassword(!showPassword)}
                                    />
                                </div>
                            </div>
                        </>
                    )}

                    {isRegister && (
                        <div className="input-block">
                            <label>Address <span className="required">*</span></label>
                            <div className="input-icon">
                                <FontAwesomeIcon icon={faAddressCard} className="input-fa-icon" />
                                <input
                                    type="text"
                                    placeholder="Enter your address"
                                    value={address}
                                    onChange={(e) => setAddress(e.target.value)}
                                />
                            </div>
                        </div>
                    )}

                    <button type="submit" className="btn btn-submit">
                        {isRegister ? 'Register' : 'Login'}
                    </button>

                    {/* {errorMessage && <p className="error-message">{errorMessage}</p>} */}

                    <div className="register-link">
                        {isRegister ? (
                            <>
                                Already have an account? <Link to="/login">Login</Link>
                            </>
                        ) : (
                            <>
                                Don't have an account? <Link to="/register">Register</Link>
                            </>
                        )}
                    </div>
                    <div className="social-media-icons">
                        <p>Or Follow Us :</p>
                        <div className="icons-container">
                            <a href="https://www.facebook.com/share/nw7dXssts7bs1hVj/?mibextid=qi2Omg" className="social-icon">
                                <FontAwesomeIcon icon={faFacebookF} />
                            </a>
                            <a href="https://www.instagram.com/_easyrides?igsh=MTM3aGhiamxkMmFjOQ==" className="social-icon">
                                <FontAwesomeIcon icon={faInstagram} />
                            </a>
                            <a href="#twitter" className="social-icon">
                                <FontAwesomeIcon icon={faTwitter} />
                            </a>
                            <a href="#gmail" className="social-icon">
                                <FontAwesomeIcon icon={faGoogle} />
                            </a>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default AuthForm;
