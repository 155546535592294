import React, { useState, useCallback } from 'react';
import axios from 'axios';
import { debounce } from 'lodash';
import { useForm } from 'react-hook-form';
import 'react-datepicker/dist/react-datepicker.css';
import MapComponent from './Geocode/MapComponent';
import SearchForm from './Geocode/SearchForm';
import VehicleList from './Geocode/VehicleList';
import BookingModal from './Geocode/BookingModal';
import './GeocodeComponent.css';

const GeocodeComponent = () => {
    const [loading, setLoading] = useState(false);
    const [pickup, setPickup] = useState('');
    const [distance, setDistance] = useState('');
    const [destination, setDestination] = useState('');
    const [pickupSuggestions, setPickupSuggestions] = useState([]);
    const [destinationSuggestions, setDestinationSuggestions] = useState([]);
    const [pickupCoordinates, setPickupCoordinates] = useState(null);
    const [destinationCoordinates, setDestinationCoordinates] = useState(null);
    const [fareDetails, setFareDetails] = useState({ totalFare: 0 });
    const [paraRoute, setParaRoute] = useState([]);
    const [vehicles, setVehicles] = useState([]);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [selectedVehicle, setSelectedVehicle] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedTime, setSelectedTime] = useState(null);
    const [isAC, setIsAC] = useState(false);  // New state for AC/Non-AC selection
    const { control, handleSubmit, formState: { errors }, register } = useForm();


      // Function to get the user's current location
      const handleGetCurrentLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                const { latitude, longitude } = position.coords;
                const currentLocation = { lat: latitude, lng: longitude };
                setPickupCoordinates(currentLocation);  // Set pickup coordinates to current location
                handleSearch();  // Trigger route calculation based on current location
            }, (error) => {
                console.error('Error getting location:', error);
            });
        } else {
            alert("Geolocation is not supported by your browser.");
        }
    };
    const fetchSuggestions = async (input, setSuggestions) => {
        try {
            const response = await axios.get(
                `https://api.olamaps.io/places/v1/autocomplete?input=${input}&api_key=tPfi8OvohL3Xyz3v8Mz6JFeSh8HeXkuGbWR3Yd4k`
            );
            const suggestions = response.data.predictions.map(prediction => ({
                description: prediction.description,
                placeId: prediction.place_id,
                location: prediction.geometry.location,
            }));
            setSuggestions(suggestions);
        } catch (error) {
            console.error('Error fetching suggestions:', error);
        }
    };

    // const debouncedFetchSuggestions = useCallback(debounce((input, setSuggestions) => {
    //     fetchSuggestions(input, setSuggestions);
    // }, 300), []);

    const debouncedFetchSuggestions = useCallback(
        debounce((input, setSuggestions) => {
            fetchSuggestions(input, setSuggestions);
        }, 300),
        [fetchSuggestions]
    );

    const handleAutocomplete = (type, value) => {
        console.log('handleAutocomplete called:', type);
        if (value) {
            if (type === 'pickup') {
                const suggestion = pickupSuggestions.find(suggestion => suggestion.description === value);
                setPickupCoordinates(suggestion.location);
            } else {
                const suggestion = destinationSuggestions.find(suggestion => suggestion.description === value);
                setDestinationCoordinates(suggestion.location);
            }
        }
    };

    // Update the fare calculation logic to account for AC/Non-AC fares
    const handleSearch = async () => {
        if (pickupCoordinates && destinationCoordinates) {
            setLoading(true);
            try {
                const response = await axios.post(
                    `https://api.olamaps.io/routing/v1/directions?origin=${pickupCoordinates.lat},${pickupCoordinates.lng}&destination=${destinationCoordinates.lat},${destinationCoordinates.lng}&api_key=tPfi8OvohL3Xyz3v8Mz6JFeSh8HeXkuGbWR3Yd4k`
                );
                const { distance, duration } = response.data.routes[0].legs[0];
                const { overview_polyline } = response.data.routes[0];

                // Adjust fare based on AC/Non-AC selection
                const farePerKm = isAC ? 28 : 24;  // Higher fare for AC
                const totalFare = (distance / 1000) * farePerKm;
                const totalDistance = distance / 1000;

                const minutes = Math.floor(duration / 60);
                const hours = Math.floor(minutes / 60);
                const remainingMinutes = minutes % 60;
                setParaRoute(overview_polyline);

                // Update fare details state
                setFareDetails({
                    distance: (distance / 1000).toFixed(2),
                    duration: `${hours} hr ${remainingMinutes} min`,
                    totalFare: Math.max(Math.round(totalFare), 500),
                    farePerKm: farePerKm,
                });

                // Call fetchVehicles with the calculated distance
                await fetchVehicles(totalDistance);
            } catch (error) {
                console.error('Error fetching directions:', error);
            } finally {
                setLoading(false);
            }
        }
    };

    const api = axios.create({
        baseURL: "https://devgas.astergo.in",
        // baseURL: "http://127.0.0.1:8000",
        // baseURL: process.env.BASE_URL,
    });

    const fetchVehicles = async (calculatedDistance) => {
        setLoading(true);
        try {
            const formattedDate = selectedDate.toISOString();
            const formattedTime = selectedTime.toISOString();

            const response = await api.get(`/api/vehicles`, {
                params: {
                    date: formattedDate,
                    time: formattedTime,
                    pickup: pickup,
                    destination: destination,
                    pickup_location: pickupCoordinates,
                    dropoff_location: destinationCoordinates,
                    distance: calculatedDistance  // Pass the calculated distance
                }
            });

            console.log(response);
            setVehicles(response.data.data);
        } catch (error) {
            console.error('Error fetching vehicles:', error);
        } finally {
            setLoading(false);  // Stop loading
        }
    };


    const openModal = (vehicle) => {
        setSelectedVehicle(vehicle);
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    return (
        <>

            <div className="section-search">
                <div className="container">
                    {loading && <div className="loader">Loading...</div>}  {/* Loader */}
                    <MapComponent
                        paraRoute={paraRoute}
                        pickupCoordinates={pickupCoordinates}  // Replace with actual coordinates
                        destinationCoordinates={destinationCoordinates}  // Replace with actual coordinates
                        setPickup={setPickup} 
                    />
                    <SearchForm
                        isAC={isAC}
                        setIsAC={setIsAC}
                        pickup={pickup}
                        setPickup={setPickup}  
                        destination={destination}
                        handleGetCurrentLocation={handleGetCurrentLocation} 
                        pickupSuggestions={pickupSuggestions}
                        destinationSuggestions={destinationSuggestions}
                        selectedDate={selectedDate}
                        selectedTime={selectedTime}
                        setSelectedDate={setSelectedDate}
                        setSelectedTime={setSelectedTime}
                        handlePickupChange={(e) => {
                            const input = e.target.value;
                            setPickup(input);
                            if (input.length > 2) {
                                debouncedFetchSuggestions(input, setPickupSuggestions);
                            } else {
                                setPickupSuggestions([]);
                            }
                        }}
                        handleDestinationChange={(e) => {
                            const input = e.target.value;
                            setDestination(input);
                            if (input.length > 2) {
                                debouncedFetchSuggestions(input, setDestinationSuggestions);
                            } else {
                                setDestinationSuggestions([]);
                            }
                        }}
                        handleAutocomplete={handleAutocomplete}
                        handleSearch={handleSearch}
                        control={control}
                        errors={errors}
                    />

                    {fareDetails && (

                            <div className="fare-card">
                            <div className="fare-items-container">
                                <div className="fare-item">
                                    <div className="icon">
                                        <i className="feather-map-pin"></i>
                                    </div>
                                    <div className="details">
                                        <h5 className="label">Distance</h5>
                                        <p className="value">{fareDetails.distance} km</p>
                                    </div>
                                </div>
                                <div className="fare-item">
                                    <div className="icon">
                                        <i className="feather-clock"></i>
                                    </div>
                                    <div className="details">
                                        <h5 className="label">Duration</h5> 
                                        <p className="value">{fareDetails.duration}</p>
                                    </div>
                                </div>
                            </div>
                            </div>


                    )}
                    <div className="col-xl-9 col-lg-8 col-sm-12 col-12">
                        <div className="row">
                            <VehicleList
                                vehicles={vehicles}
                                openModal={openModal}
                                fareDetails={fareDetails}
                                setFareDetails={setFareDetails}
                            />
                        </div>
                    </div>
                    <BookingModal
                        modalIsOpen={modalIsOpen}
                        closeModal={closeModal}
                        selectedVehicle={selectedVehicle}
                        selectedDate={selectedDate}
                        selectedTime={selectedTime}
                        setSelectedDate={setSelectedDate}
                        setSelectedTime={setSelectedTime}
                        handleSubmit={handleSubmit}
                        control={control}
                        errors={errors}
                        register={register}
                        pickup={pickup}
                        destination={destination}
                        fareDetails={fareDetails}
                        pickupCoordinates={pickupCoordinates}
                        destinationCoordinates={destinationCoordinates}
                        isAC={isAC}
                        setIsAC={setIsAC}
                    />
                </div>
            </div>
        </>
    );
};

export default GeocodeComponent;
