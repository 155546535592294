import React from 'react';
import { Link } from 'react-router-dom';
import './ContactUs.css'; // Make sure to create a CSS file for styling
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const ContactUs = () => {
  return (
    <div className="contact-page">
      <div className="back-arrow">
        <Link to="/">
          {/* You can replace this with an actual arrow icon, using a character or an icon library like FontAwesome */}
          <FontAwesomeIcon icon={faArrowLeft} />

        </Link>
      </div>
      <div className="contact-header">
        <h2>Contact Us</h2>

      </div>
      <div className="contact-form-container">
        <form className="contact-form">
          <div className="form-group">
            <label htmlFor="email">Your Email Address</label>
            <input type="email" id="email" placeholder="you@example.com" required />
          </div>
          <div className="form-group">
            <label>Select a topic:</label>
            <div className="button-group">
              <button type="button">Booking Related</button>
              <button type="button">Driver Behaviour </button>
              <button type="button">On Timing</button>
              <button type="button">Payment Issue</button>
              <button type="button">App Related</button>
              <button type="button">Suggestion</button>
              <button type="button">Signing in</button>
              <button type="button">Other</button>
            </div>
          </div>
          <div className="form-group">
            <label>Or tell us what you need help with:</label>
            <input type="text" placeholder="Enter a topic, like 'notifications'" />
          </div>
          <button type="submit" className="submit-btn">Get Help</button>
        </form>

      </div>
    </div>
  );
};

export default ContactUs;
