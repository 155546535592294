// src/App.js
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage';
import LoginPage from './pages/LoginPage';
import RegisterPage from './pages/RegisterPage';
import BookingSummary from './components/Booking/BookingSummary';
import BookingHistory from './components/Booking/BookingHistory';
import BookingStatus from './components/Booking/BookingStatus';
import Policy from './components/Main/Policy';
import ContactUs from './components/Main/ContactUs';
import Header from './components/Main/Header';

const App = () => {
  return (
    <Routes>
      <Route path="/" end element={<HomePage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/register" element={<RegisterPage />} />
      <Route path="/booking-status" element={<BookingStatus />} />
      <Route path="/booking-summary" element={<BookingSummary />} />
      <Route path="/booking-history" element={<BookingHistory />} />
      <Route path="/policy" element={<Policy />} />
      <Route path="/contact-us" element={<ContactUs />} />
      <Route path="/header" element={<Header />} />
    </Routes>


  );
};

export default App;
