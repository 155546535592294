import React, { useState } from 'react';
import { Navigate, useNavigate, Link } from 'react-router-dom';
import './BookingStatus.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarCheck, faUserCheck, faClock, faFlagCheckered, faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const BookingStatus = () => {
    const navigate = useNavigate();

    // Sample data for pickup, driver, and destination details
    const pickupLocation = '123 Main St, Springfield shfiedvfi cewbbid';
    const pickupTime = '10:30 AM';
    const driverName = 'John Doe';
    const arrivalTime = '10:45 AM, 25th Sept';
    const destinationLocation = '456 Elm St, Springfield jbvdfvrfibvro ';
    const customerName = 'Ravi Kumar';

    // Active step state
    const [activeStep, setActiveStep] = useState(3); // Example: 1 for Ride Placed

    return (
        <div className="booking-status-container">
            <h1 className="status-title">Booking Status</h1>
            <div className="back-arrow">
                <Link to="/">
                    <FontAwesomeIcon icon={faArrowLeft} />
                </Link>
            </div>
            <div className="greeting-container">
                <h2 className="greeting-text">Hello, {customerName}!</h2>
                <p className="greeting-subtext">Thank you for choosing our service. Below is your booking status:</p>
            </div>



            <div className="status-steps">
                {/* Ride Placed */}
                <div className={`status-step ${activeStep >= 1 ? 'active' : ''}`}>
                    <div className="icon-container animate-icon">
                        <FontAwesomeIcon icon={faCalendarCheck} className="status-icon" />
                    </div>
                    <p className="status-text">Ride Placed</p>
                    {activeStep >= 1 && (
                        <div className="step-details">
                            <p><strong >Pickup Location:</strong>
                                <span>{pickupLocation}</span> </p>
                            <p><strong >Pickup Time:</strong> {pickupTime}</p>
                        </div>
                    )}
                </div>

                <div className="line-container">
                    <div className="dotted-line"></div>
                </div>

                {/* Driver Confirmed */}
                <div className={`status-step ${activeStep >= 2 ? 'active' : ''}`}>
                    <div className="icon-container animate-icon">
                        <FontAwesomeIcon icon={faUserCheck} className="status-icon" />
                    </div>
                    <p className="status-text">Driver Confirmed</p>
                    {activeStep >= 2 && (
                        <div className="step-details">
                            <p><strong>Driver Name:</strong> {driverName}</p>
                        </div>
                    )}
                </div>

                <div className="line-container">
                    <div className="dotted-line"></div>
                </div>

                {/* Scheduled Arrival */}
                <div className={`status-step ${activeStep >= 3 ? 'active' : ''}`}>
                    <div className="icon-container animate-icon">
                        <FontAwesomeIcon icon={faClock} className="status-icon" />
                    </div>
                    <p className="status-text">Scheduled Arrival</p>
                    {activeStep >= 3 && (
                        <div className="step-details">
                            <p><strong>Arrival Time:</strong> {arrivalTime}</p>
                        </div>
                    )}
                </div>

                <div className="line-container">
                    <div className="dotted-line"></div>
                </div>

                {/* Ride Completed */}
                <div className={`status-step ${activeStep >= 4 ? 'active' : ''}`} >
                    <div className="icon-container animate-icon"  >
                        <FontAwesomeIcon icon={faFlagCheckered} className="status-icon" />
                    </div>
                    <p className="status-text" >Ride Completed</p>
                    {activeStep >= 4 && (
                        <div className="step-details">
                            <p><strong>Destination Location:</strong> {destinationLocation}</p>
                        </div>
                    )}
                </div>
            </div>

        </div>
    );
};

export default BookingStatus;
