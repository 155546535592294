import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getBookingDetails } from '../../features/booking/bookingSlice';
import './BookingSummary.css';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import ModernLoading from '../Common/ModernLoading'; // Import modern loading component

const BookingSummary = () => {
    const dispatch = useDispatch();
    const { bookingDetails, status, error } = useSelector((state) => state.bookings);
    const { id } = useSelector((state) => state.auth.user);
    
    const bookingData = {
        driverName: "Rahul",
        driverRating: 4.5,
        carImage: "/assets/img/car7.jpg",
    };

    const handleEmergencyClick = () => {
        window.location.href = 'tel:100';
    };

    useEffect(() => {
        dispatch(getBookingDetails(id));
    }, [dispatch, id]);

    if (status === 'loading') {
        return <ModernLoading />; // Use modern loading effect
    }

    if (error) {
        return <p>{error}</p>;
    }

    return (
        <div className="booking-summary">
            <div className="back-arrow">
                <Link to="/">
                    <FontAwesomeIcon icon={faArrowLeft} />
                </Link>
            </div>
            <h2 className="booking-title">Booking Summary</h2>
            {bookingDetails && (
                <>
                    <div className="summary-card">
                        <div className="car-info">
                            <img src={bookingData.carImage} alt="Car" className="car-image" />
                            <div className="driver-info">
                                <p><strong>Driver Name:</strong> {bookingData.driverName}</p>
                                <p><strong>Rating:</strong> <span className="rating-star">{bookingData.driverRating} ★</span></p>
                                <p><strong>Car Number:</strong> {bookingDetails.vehicle.number_plate}</p>
                            </div>
                        </div>
                        <div className="booking-details">
                            <p><strong>Journey Date:</strong> {bookingDetails.booking_date} <span>{bookingDetails.booking_time}</span></p>
                            <p><strong>Pickup:</strong> {bookingDetails.pickup_location}</p>
                            <p><strong>Destination:</strong> {bookingDetails.dropoff_location}</p>
                            <p><strong>Total Fare:</strong> ₹{bookingDetails.amount}</p>
                            <p><strong>Amount Paid:</strong> ₹{bookingDetails.amount_paid}</p>
                            <p><strong>Amount Due:</strong> ₹{bookingDetails.amount_due}</p>
                        </div>
                    </div>
                    <div className="emergency-button-container">
                        <button className="emergency-button" onClick={handleEmergencyClick}>
                            Emergency: Dial 100
                        </button>
                    </div>
                </>
            )}
        </div>
    );
};

export default BookingSummary;
