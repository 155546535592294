import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { logout } from '../../features/auth/authSlice';
import { toast } from 'react-toastify'; // Import toast
import './Header.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faLock, faRightFromBracket, faChevronDown, faBars, faTimes } from '@fortawesome/free-solid-svg-icons';

const Header = () => {
    const dispatch = useDispatch();
    const { token, user } = useSelector((state) => state.auth);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    const handleLogout = () => {
        dispatch(logout());
        toast.success("Logged out successfully!"); // Show toast message on logout
    };

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    // Get user initials from the name (with safety check)
    const getUserInitials = (name) => {
        if (!name) return ''; // Ensure name exists
        const nameParts = name.split(' ');
        const initials = nameParts[0][0] + (nameParts[1] ? nameParts[1][0] : '');
        return initials.toUpperCase();
    };

    return (
        <>
            <header className="header">
                <div className="container-fluid">
                    <nav className="navbar navbar-expand-lg header-nav">
                        <div className="navbar-header">
                            <button id="mobile_btn" onClick={toggleMobileMenu}>
                                <span className="bar-icon">
                                    <FontAwesomeIcon icon={isMobileMenuOpen ? faTimes : faBars} />
                                </span>
                            </button>
                            <Link to="/" className="navbar-brand logo">
                                <img src="/assets/img/logo7.png" className="img-fluid" alt="Logo" />
                            </Link>
                        </div>

                        <div className={`main-menu-wrapper ${isMobileMenuOpen ? 'open' : ''}`}>
                            <div className="menu-header">
                                <Link to="/" className="menu-logo">
                                    <img src="/assets/img/logo7.png" className="img-fluid" alt="Logo" />
                                </Link>
                                <a id="menu_close" className="menu-close" href="javascript:void(0);">
                                    <i className="fas fa-times"></i>
                                </a>
                            </div>
                            <ul className="main-nav">
                                <li className="active"><Link to="/">Home</Link></li>
                                <li><Link to="/booking-status">Booking Status</Link></li>
                                <li><Link to="/booking-summary">My Rides</Link></li>
                                <li><Link to="/booking-history">History</Link></li>
                                <li><Link to="/contact-us">Contact Us</Link></li>
                                <li><Link to="/policy">Policy</Link></li>
                                {!token ? (
                                    <>
                                        <li className="login-link">
                                            <Link to="/register">Sign Up</Link>
                                        </li>
                                        <li className="login-link">
                                            <Link to="/login">Sign In</Link>
                                        </li>
                                    </>
                                ) : (
                                    <li className="login-link">
                                        <button className="btn-logout" onClick={handleLogout}>
                                            <FontAwesomeIcon icon={faRightFromBracket} /> Logout
                                        </button>
                                    </li>
                                )}
                            </ul>
                        </div>

                        {/* Ensure logout is visible in the header (desktop) */}
                        <ul className="nav header-navbar-rht">
                            {!token ? (
                                <>
                                    <li className="nav-item">
                                        <Link className="nav-link header-login" to="/login">
                                            <FontAwesomeIcon icon={faUser} /> Sign In
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link header-reg" to="/register">
                                            <FontAwesomeIcon icon={faLock} /> Sign Up
                                        </Link>
                                    </li>
                                </>
                            ) : (
                                <>
                                    <li className="nav-item user-menu">
                                        <div className="user-logo" onClick={toggleDropdown}>
                                            <div className="initials-circle">
                                                {getUserInitials(user?.name)} {/* Optional chaining */}
                                            </div>
                                            <FontAwesomeIcon icon={faChevronDown} style={{ color: 'white' }} />
                                        </div>

                                        {isDropdownOpen && (
                                            <div className="user-dropdown">
                                                <p><strong>{user?.name}</strong></p> {/* Optional chaining */}
                                                <button className="btn-logout" onClick={handleLogout}>
                                                    <FontAwesomeIcon icon={faRightFromBracket} /> Logout
                                                </button>
                                            </div>
                                        )}
                                    </li>
                                </>
                            )}
                        </ul>
                    </nav>
                </div>
            </header>
        </>
    );
};

export default Header;
