import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchBookingDetails, fetchBookingHistory, updateBookingStatus } from '../../api'; // Assuming you have these API functions

const initialState = {
    bookingDetails: null,
    bookingHistory: [],
    status: 'idle', // idle | loading | succeeded | failed
    error: null,
};

export const getBookingDetails = createAsyncThunk(
    'booking/getBookingDetails',
    async (bookingId) => {
        const response = await fetchBookingDetails(bookingId);
        return response.data;
    }
);

export const getBookingHistory = createAsyncThunk(
    'booking/getBookingHistory',
    async (userId) => {
        const response = await fetchBookingHistory(userId);
        return response.data;
    }
);

export const updateStatus = createAsyncThunk(
    'booking/updateStatus',
    async ({ bookingId, status }) => {
        const response = await updateBookingStatus(bookingId, status);
        return response.data;
    }
);

const bookingSlice = createSlice({
    name: 'booking',
    initialState,
    reducers: {
        clearBooking: (state) => {
            state.bookingDetails = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getBookingDetails.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getBookingDetails.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.bookingDetails = action.payload;
            })
            .addCase(getBookingDetails.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(getBookingHistory.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getBookingHistory.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.bookingHistory = action.payload;
            })
            .addCase(getBookingHistory.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(updateStatus.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(updateStatus.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.bookingDetails = action.payload; // Update booking details with new status
            })
            .addCase(updateStatus.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    },
});

export const { clearBooking } = bookingSlice.actions;

export default bookingSlice.reducer;
