import React, { useState } from 'react';
import './VehicleList.css';
import '@fortawesome/fontawesome-free/css/all.min.css'; // Import Font Awesome

const VehicleList = ({ vehicles, openModal, fareDetails, setFareDetails }) => {
    const [popupVisible, setPopupVisible] = useState(false);
    const [popupContent, setPopupContent] = useState({ driverName: '', location: '' });

    const handleBookNow = (vehicle) => {
        const fare = vehicle.calculated_fare;
        setFareDetails({ totalFare: fare });
        openModal(vehicle, fare);
    };

    const handleDriverClick = (driverName, location) => {
        setPopupContent({ driverName, location });
        setPopupVisible(true);
    };

    const closePopup = () => {
        setPopupVisible(false);
    };

    return (
        <div className="vehicle-list-container">
            {vehicles.map((vehicle, index) => (
                <div className="vehicle-card" key={index}>
                    {/* Left: Vehicle Image */}
                    <div className="vehicle-left">
                        <img src={vehicle.image || '/assets/img/ertiga_white.png'} className="vehicle-img" alt={vehicle.model} />
                    </div>

                    {/* Right: Vehicle Details */}
                    <div className="vehicle-right">
                        <div className="vehicle-name-driver-row">
                            <h4 className="vehicle-model">{vehicle.model}</h4> {/* Vehicle model */}
                            {/* Driver Details Button in the same row */}
                            <button
                                className="driver-details-btn"
                                onClick={() => handleDriverClick(vehicle.driver_name, 'Purulia')} // Replace 'Purulia' with dynamic location
                            >
                                Details
                            </button>
                        </div>

                        <div className="vehicle-info">
                            <div className="info-row">
                                <img src="/assets/img/icons/license-plate.svg" alt="License Plate" style={{ width: '20px', marginRight: '5px' }} />
                                <p>{vehicle.number_plate}</p>
                                <img src="/assets/img/icons/family.svg" alt="Seats" style={{ width: '20px', marginRight: '5px' }} />
                                <p>{vehicle.number_of_seats}</p>
                                <img src="/assets/img/icons/luggage.svg" alt="Luggage" style={{ width: '20px', marginRight: '5px' }} />
                                <p>{vehicle.luggage_capacity} 4</p>
                            </div>
                        </div>

                        {/* Fare and Book Now Button in the same row */}
                        <div className="fare-book-row">
                            <p className="fare-amount"> ₹ {vehicle.calculated_fare}</p>
                            <button className="btn btn-order" onClick={() => handleBookNow(vehicle)}>
                                Book Now
                            </button>
                        </div>
                    </div>
                </div>
            ))}

            {/* Popup for Driver Information */}
            {popupVisible && (
                <div className="popup">
                    <div className="popup-content">
                        <span className="close-btn" onClick={closePopup}>&times;</span>
                        <h4>Driver Information</h4>
                        <p><strong><img src="/assets/img/cars/driver.png" alt="car" style={{ width: '20px', marginRight: '5px' }} /></strong> {popupContent.driverName}</p>
                        <p><strong><i className="feather-map-pin me-2"></i></strong> {popupContent.location}</p>
                    </div>
                </div>
            )}
        </div>
    );
};

export default VehicleList;
