// import React, { useEffect, useState } from 'react';
// import { Map as MapLibreMap, NavigationControl } from "maplibre-gl";
// import 'maplibre-gl/dist/maplibre-gl.css';
// import polyline from '@mapbox/polyline';
// import * as maplibregl from 'maplibre-gl';

// const MapComponent = ({ paraRoute }) => {
//     const [mapReady, setMapReady] = useState(false);
//     // console.log(paraRoute);

//     useEffect(() => {
//         if (!mapReady) return;

//         // Initialize the map
//         const map = new MapLibreMap({
//             container: "central-map",
//             center: [86.36377, 23.33608],
//             zoom: 15,
//             style: "https://api.olamaps.io/tiles/vector/v1/styles/default-light-standard/style.json",
//             transformRequest: (url, resourceType) => {
//                 if (url.includes("?")) {
//                     url = url + "&api_key=tPfi8OvohL3Xyz3v8Mz6JFeSh8HeXkuGbWR3Yd4k";
//                 } else {
//                     url = url + "?api_key=tPfi8OvohL3Xyz3v8Mz6JFeSh8HeXkuGbWR3Yd4k";
//                 }
//                 return { url, resourceType };
//             },
//         });

//         // Add navigation control
//         const nav = new NavigationControl({
//             visualizePitch: true,
//         });
//         map.addControl(nav, "top-left");

//         // Decode the polyline
//         const encodedPolyline = paraRoute;
//         const decodedPolyline = polyline.decode(encodedPolyline);

//         const coordinates = decodedPolyline.map(([lat, lon]) => [lon, lat]);

//         // Debug: Log the coordinates to verify
//         console.log('Decoded Coordinates:', coordinates);

//         // Convert to GeoJSON
//         const routeGeoJSON = {
//             type: 'Feature',
//             geometry: {
//                 type: 'LineString',
//                 coordinates: coordinates,
//             },
//         };

//         // Add the route to the map
//         map.on('load', () => {
//             map.addSource('route', {
//                 type: 'geojson',
//                 data: routeGeoJSON,
//             });

//             map.addLayer({
//                 id: 'route-layer',
//                 type: 'line',
//                 source: 'route',
//                 layout: {
//                     'line-join': 'round',
//                     'line-cap': 'round'
//                 },
//                 paint: {
//                     'line-color': '#FF0000',
//                     'line-width': 4
//                 }
//             });

//             // Calculate the bounds of the route
//             const bounds = coordinates.reduce((bounds, coord) => {
//                 return bounds.extend(coord);
//             }, new maplibregl.LngLatBounds(coordinates[0], coordinates[0]));

//             // Fit the map to the bounds of the route
//             map.fitBounds(bounds, {
//                 padding: 20
//             });
//         });

//     }, [mapReady, paraRoute]);

//     return (
//         <div
//             style={{ height: '36vh', overflow: 'hidden' }}
//             ref={() => setMapReady(true)}
//             id="central-map"
//         />
//     );
// };

// export default MapComponent;

 
/////////////////////////-------------------MainPart----------------------------////////////////////////////////

// import React, { useEffect, useState } from 'react';
// import { Map as MapLibreMap, NavigationControl } from "maplibre-gl";
// import 'maplibre-gl/dist/maplibre-gl.css';
// import polyline from '@mapbox/polyline';
// import * as maplibregl from 'maplibre-gl';

// const MapComponent = ({ paraRoute, pickupCoordinates, destinationCoordinates }) => {
//     const [mapReady, setMapReady] = useState(false);

//     useEffect(() => {
//         if (!mapReady) return;

//         // Initialize the map
//         const map = new MapLibreMap({
//             container: "central-map",
//             center: [86.36377, 23.33608],
//             zoom: 15,
//             style: "https://api.olamaps.io/tiles/vector/v1/styles/default-light-standard/style.json",
//             transformRequest: (url, resourceType) => {
//                 if (url.includes("?")) {
//                     url = url + "&api_key=tPfi8OvohL3Xyz3v8Mz6JFeSh8HeXkuGbWR3Yd4k";
//                 } else {
//                     url = url + "?api_key=tPfi8OvohL3Xyz3v8Mz6JFeSh8HeXkuGbWR3Yd4k";
//                 }
//                 return { url, resourceType };
//             },
//         });

//         // Add navigation control
//         const nav = new NavigationControl({
//             visualizePitch: true,
//         });
//         map.addControl(nav, "top-left");

//         // Decode the polyline
//         const encodedPolyline = paraRoute;
//         const decodedPolyline = polyline.decode(encodedPolyline);

//         const coordinates = decodedPolyline.map(([lat, lon]) => [lon, lat]);

//         // Debug: Log the coordinates to verify
//         console.log('Decoded Coordinates:', coordinates);

//         // Convert to GeoJSON
//         const routeGeoJSON = {
//             type: 'Feature',
//             geometry: {
//                 type: 'LineString',
//                 coordinates: coordinates,
//             },
//         };

//         // Add the route to the map
//         map.on('load', () => {
//             map.addSource('route', {
//                 type: 'geojson',
//                 data: routeGeoJSON,
//             });

//             map.addLayer({
//                 id: 'route-layer',
//                 type: 'line',
//                 source: 'route',
//                 layout: {
//                     'line-join': 'round',
//                     'line-cap': 'round'
//                 },
//                 paint: {
//                     'line-color': '#FF0000',
//                     'line-width': 4
//                 }
//             });

//             // Calculate the bounds of the route
//             const bounds = coordinates.reduce((bounds, coord) => {
//                 return bounds.extend(coord);
//             }, new maplibregl.LngLatBounds(coordinates[0], coordinates[0]));

//             // Fit the map to the bounds of the route
//             map.fitBounds(bounds, {
//                 padding: 20
//             });

//             // Add pickup marker (green)
//             new maplibregl.Marker({ color: 'green' })
//                 .setLngLat(pickupCoordinates)
//                 .addTo(map);
                

//             // Add destination marker (red)
//             new maplibregl.Marker({ color: 'red' })
//                 .setLngLat(destinationCoordinates)
//                 .addTo(map);
//         });

//     }, [mapReady, paraRoute, pickupCoordinates, destinationCoordinates]);

//     return (
//         <div
//             style={{ position: 'relative', height: '70vh', overflow: 'hidden' }}
//             ref={() => setMapReady(true)}
//             id="central-map"
//         />
        
//     );
// };

// export default MapComponent;

///////////////////////////////////////////////////////////////////////////////////////////////////




import React, { useEffect, useState } from 'react';
import { Map as MapLibreMap, NavigationControl } from "maplibre-gl";
import 'maplibre-gl/dist/maplibre-gl.css';
import polyline from '@mapbox/polyline';
import * as maplibregl from 'maplibre-gl';

const MapComponent = ({ paraRoute, pickupCoordinates, destinationCoordinates }) => {
    const [mapReady, setMapReady] = useState(false);

    useEffect(() => {
        if (!mapReady) return;

        // Initialize the map
        const map = new MapLibreMap({
            container: "central-map",
            center: [86.36377, 23.33608],
            zoom: 15,
            style: "https://api.olamaps.io/tiles/vector/v1/styles/default-light-standard/style.json",
            transformRequest: (url, resourceType) => {
                if (url.includes("?")) {
                    url = url + "&api_key=tPfi8OvohL3Xyz3v8Mz6JFeSh8HeXkuGbWR3Yd4k";
                } else {
                    url = url + "?api_key=tPfi8OvohL3Xyz3v8Mz6JFeSh8HeXkuGbWR3Yd4k";
                }
                return { url, resourceType };
            },
        });

        // Add navigation control
        const nav = new NavigationControl({
            visualizePitch: true,
        });
        map.addControl(nav, "bottom-left");

        // Decode the polyline
        const encodedPolyline = paraRoute;
        const decodedPolyline = polyline.decode(encodedPolyline);
        const coordinates = decodedPolyline.map(([lat, lon]) => [lon, lat]);

        // Convert to GeoJSON
        const routeGeoJSON = {
            type: 'Feature',
            geometry: {
                type: 'LineString',
                coordinates: coordinates,
            },
        };

        // Add the route to the map
        map.on('load', () => {
            map.addSource('route', {
                type: 'geojson',
                data: routeGeoJSON,
            });

            map.addLayer({
                id: 'route-layer',
                type: 'line',
                source: 'route',
                layout: {
                    'line-join': 'round',
                    'line-cap': 'round'
                },
                paint: {
                    'line-color': '#FF0000',
                    'line-width': 4
                }
            });

            // Calculate the bounds of the route
            const bounds = coordinates.reduce((bounds, coord) => {
                return bounds.extend(coord);
            }, new maplibregl.LngLatBounds(coordinates[0], coordinates[0]));

            // Fit the map to the bounds of the route
            map.fitBounds(bounds, {
                padding: 20
            });

            // Add pickup marker (green)

           // Add pickup marker (green)
const pickupMarker = new maplibregl.Marker({ color: 'green' })
.setLngLat(pickupCoordinates)
.addTo(map);

// Add hover effects and animations to the pickup marker
pickupMarker.getElement().addEventListener('mouseenter', () => {
pickupMarker.getElement().style.transform = 'scale(1.2)';
pickupMarker.getElement().style.transition = 'transform 0.3s';
});

pickupMarker.getElement().addEventListener('mouseleave', () => {
pickupMarker.getElement().style.transform = 'scale(1)';
pickupMarker.getElement().style.transition = 'transform 0.3s';
});

// Add destination marker (red)
const destinationMarker = new maplibregl.Marker({ color: 'red' })
.setLngLat(destinationCoordinates)
.addTo(map);

// Add hover effects and animations to the destination marker
destinationMarker.getElement().addEventListener('mouseenter', () => {
destinationMarker.getElement().style.transform = 'scale(1.2)';
destinationMarker.getElement().style.transition = 'transform 0.3s';
});

destinationMarker.getElement().addEventListener('mouseleave', () => {
destinationMarker.getElement().style.transform = 'scale(1)';
destinationMarker.getElement().style.transition = 'transform 0.3s';
});

// Add moving marker (blue)
const movingMarker = new maplibregl.Marker({ color: 'blue' })
.setLngLat(coordinates[0])
.addTo(map);

// Add hover effects and animations to the moving marker
movingMarker.getElement().addEventListener('mouseenter', () => {
movingMarker.getElement().style.transform = 'scale(1.2)';
movingMarker.getElement().style.transition = 'transform 0.3s';
});

movingMarker.getElement().addEventListener('mouseleave', () => {
movingMarker.getElement().style.transform = 'scale(1)';
movingMarker.getElement().style.transition = 'transform 0.3s';
});

// Animation variables
let step = 0;
const steps = 500; // Total steps for the animation
const animationSpeed = 0.8; // Adjust this value to control the speed of the marker

function animateMarker() {
step += animationSpeed;
if (step >= coordinates.length - 1) {
    step = 0; // Reset the animation once it reaches the end
}

const nextStep = Math.min(Math.floor(step), coordinates.length - 1);
movingMarker.setLngLat(coordinates[nextStep]);

requestAnimationFrame(animateMarker);
}

animateMarker(); // Start the animation


            new maplibregl.Marker({ color: 'green' })
                .setLngLat(pickupCoordinates)
                .addTo(map);

            // Add destination marker (red)
            new maplibregl.Marker({ color: 'red' })
                .setLngLat(destinationCoordinates)
                .addTo(map);

        });

    }, [mapReady, paraRoute, pickupCoordinates, destinationCoordinates]);

    return (
        
        <div
            style={{ position: 'relative', height: '70vh', overflow: 'hidden' }}
            ref={() => setMapReady(true)}
            id="central-map"
            
        />    
         
    );
};

export default MapComponent;



// import React, { useEffect, useState } from 'react';
// import { Map as MapLibreMap, NavigationControl } from "maplibre-gl";
// import 'maplibre-gl/dist/maplibre-gl.css';
// import polyline from '@mapbox/polyline';
// import * as maplibregl from 'maplibre-gl';

// const MapComponent = ({ paraRoute, pickupCoordinates, destinationCoordinates }) => {
//     const [mapReady, setMapReady] = useState(false);

//     useEffect(() => {
//         if (!mapReady) return;

//         // Initialize the map
//         const map = new MapLibreMap({
//             container: "central-map",
//             center: [86.36377, 23.33608],
//             zoom: 15,
//             style: "https://api.olamaps.io/tiles/vector/v1/styles/default-light-standard/style.json",
//             transformRequest: (url, resourceType) => {
//                 if (url.includes("?")) {
//                     url = url + "&api_key=tPfi8OvohL3Xyz3v8Mz6JFeSh8HeXkuGbWR3Yd4k";
//                 } else {
//                     url = url + "?api_key=tPfi8OvohL3Xyz3v8Mz6JFeSh8HeXkuGbWR3Yd4k";
//                 }
//                 return { url, resourceType };
//             },
//         });

//         // Add navigation control
//         const nav = new NavigationControl({
//             visualizePitch: true,
//         });
//         map.addControl(nav, "top-left");

//         // Decode the polyline
//         const encodedPolyline = paraRoute;
//         const decodedPolyline = polyline.decode(encodedPolyline);

//         const coordinates = decodedPolyline.map(([lat, lon]) => [lon, lat]);

//         // Debug: Log the coordinates to verify
//         console.log('Decoded Coordinates:', coordinates);

//         // Convert to GeoJSON
//         const routeGeoJSON = {
//             type: 'Feature',
//             geometry: {
//                 type: 'LineString',
//                 coordinates: coordinates,
//             },
//         };

//         // Add the route to the map
//         map.on('load', () => {
//             map.addSource('route', {
//                 type: 'geojson',
//                 data: routeGeoJSON,
//             });

//             map.addLayer({
//                 id: 'route-layer',
//                 type: 'line',
//                 source: 'route',
//                 layout: {
//                     'line-join': 'round',
//                     'line-cap': 'round'
//                 },
//                 paint: {
//                     'line-color': '#FF0000',
//                     'line-width': 4
//                 }
//             });

//             // Calculate the bounds of the route
//             const bounds = coordinates.reduce((bounds, coord) => {
//                 return bounds.extend(coord);
//             }, new maplibregl.LngLatBounds(coordinates[0], coordinates[0]));

//             // Fit the map to the bounds of the route
//             map.fitBounds(bounds, {
//                 padding: 20
//             });

//             // Add pickup marker (green)
//             new maplibregl.Marker({ color: 'green' })
//                 .setLngLat(pickupCoordinates)
//                 .addTo(map);

//             // Add destination marker (red)
//             new maplibregl.Marker({ color: 'red' })
//                 .setLngLat(destinationCoordinates)
//                 .addTo(map);
//         });

//     }, [mapReady, paraRoute, pickupCoordinates, destinationCoordinates]);

//     return (
//         <div style={{ position: 'relative', height: '70vh', overflow: 'hidden' }}>
//             <div
//                 style={{ height: '100%', width: '100%' }}
//                 ref={() => setMapReady(true)}
//                 id="central-map"
//             />
//             <div className="floating-info" style={{ position: 'absolute', top: '10px', left: '50%', transform: 'translateX(-50%)', backgroundColor: 'rgba(255, 255, 255, 0.9)', borderRadius: '8px', padding: '15px', boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', width: '90%', maxWidth: '400px', zIndex: 1000 }}>
//                 <div className="location-info" style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
//                     <div className="pickup-location" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
//                         <label style={{ fontWeight: 'bold', fontSize: '14px', marginRight: '10px' }}>Pickup Location</label>
//                         <input type="text" value="Dulmi Bandh- Ketika Road, Dulmi Bandh- ke" disabled style={{ width: 'calc(100% - 25px)', padding: '5px', border: '1px solid #ddd', borderRadius: '4px', backgroundColor: '#f9f9f9', fontSize: '14px' }} />
//                         <span className="remove-location" style={{ color: '#ff4d4d', cursor: 'pointer', fontSize: '18px', marginLeft: '10px' }}>✖</span>
//                     </div>
//                     <div className="destination-location" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
//                         <label style={{ fontWeight: 'bold', fontSize: '14px', marginRight: '10px' }}>Destination Location</label>
//                         <input type="text" value="Birsa Munda Airport Terminal (IXR), Airport R" disabled style={{ width: 'calc(100% - 25px)', padding: '5px', border: '1px solid #ddd', borderRadius: '4px', backgroundColor: '#f9f9f9', fontSize: '14px' }} />
//                         <span className="remove-location" style={{ color: '#ff4d4d', cursor: 'pointer', fontSize: '18px', marginLeft: '10px' }}>✖</span>
//                     </div>
//                     <div className="pickup-date-time" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
//                         <label style={{ fontWeight: 'bold', fontSize: '14px', marginRight: '10px' }}>Pickup Date</label>
//                         <input type="text" value="August 28, 2024" disabled style={{ width: 'calc(50% - 25px)', padding: '5px', border: '1px solid #ddd', borderRadius: '4px', backgroundColor: '#f9f9f9', fontSize: '14px' }} />
//                         <input type="text" value="10:15 AM" disabled style={{ width: 'calc(50% - 25px)', padding: '5px', border: '1px solid #ddd', borderRadius: '4px', backgroundColor: '#f9f9f9', fontSize: '14px' }} />
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default MapComponent;

