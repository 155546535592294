// src/app/store.js
import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import authReducer from '../features/auth/authSlice';
import productReducer from '../features/products/productSlice';
import bookingReducer from '../features/booking/bookingSlice';
import vehicleReducer from '../features/vehicleSlice';

const persistConfig = {
    key: 'root',
    storage,
};
// const persistedReducer = persistReducer(persistConfig, bookingReducer);

const rootReducer = combineReducers({
    auth: persistReducer(persistConfig, authReducer),
    products: productReducer,
    bookings: bookingReducer,
    vehicles: persistReducer(persistConfig, vehicleReducer),
});

const store = configureStore({
    reducer: rootReducer,
});

const persistor = persistStore(store);

export { store, persistor };
