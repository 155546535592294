// src/features/vehicles/vehicleSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchVehicles } from '../api/all';

const initialState = {
    items: [],
    status: 'idle',
    error: null,
};

export const getVehicles = createAsyncThunk('vehicles/getVehicles', async () => {
    const response = await fetchVehicles();
    return response.data;
});

const vehicleSlice = createSlice({
    name: 'vehicles',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getVehicles.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getVehicles.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.items = action.payload;
            })
            .addCase(getVehicles.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    },
});

export default vehicleSlice.reducer;
